import React from 'react'

export default function Enviro() {
    return (
        <svg width="94" height="77" viewBox="0 0 94 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72.2994 20.6751C72.2387 37.3034 56.9902 57.0125 68.9887 76.284H65.8929C54.6304 56.2334 69.5249 36.3852 69.6235 20.6397C58.7353 19.8581 51.0263 11.3189 53.376 0.776334C64.7472 -0.146897 72.3373 10.1857 72.2994 20.6777V20.6751Z" fill="#7FB24A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5726 17.587C44.6327 29.0932 54.2942 37.6502 66.5153 36.4689C66.4874 26.6144 57.8831 16.0693 46.5726 17.587Z" fill="#C6322D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.1153 36.3174C40.1846 47.9501 50.5543 55.6319 62.6261 53.3908C61.7409 43.5768 52.2488 33.8209 41.1153 36.3174Z" fill="#9C2871" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4036 30.0692C89.9079 41.7272 78.6808 48.0887 66.9731 44.3932C69.0496 34.7613 79.6596 26.2347 90.4036 30.0692Z" fill="#FAA61A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M89.584 49.0245C87.4721 60.5004 75.466 65.2379 64.3882 59.9464C67.7849 50.6964 79.4774 43.7304 89.584 49.0245Z" fill="#00652E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M93.2261 9.90795C94.1569 21.5381 83.7872 29.2225 71.7153 26.9789C72.6005 17.1648 82.0926 7.4089 93.2261 9.90795Z" fill="#0083CA" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.71582 76.1451H2.41291C5.26584 76.1451 5.71098 73.8028 5.76915 73.4007C5.82479 72.9985 6.03977 70.6562 3.18431 70.6562H1.48975L0.71835 76.1476L0.71582 76.1451ZM1.93489 71.1191H2.98956C4.44638 71.1191 5.48082 71.8248 5.25825 73.3981C5.03821 74.9714 3.80649 75.6771 2.34715 75.6771H1.29501L1.93489 71.1166V71.1191Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.53027 76.1447H10.013L10.0787 75.6792H7.10946L7.41043 73.5394H10.0762L10.142 73.074H7.47366L7.74934 71.1187H10.5947L10.6604 70.6533H7.30421L6.53027 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4008 76.1447H12.9117L15.7697 70.6533H15.2259L12.765 75.4693L11.6749 70.6533H11.1235L12.4008 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7241 76.1447H19.2068L19.2701 75.6792H16.3008L16.6018 73.5394H19.2701L19.3358 73.074H16.6675L16.9432 71.1187H19.7885L19.8543 70.6533H16.4955L15.7241 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3193 76.1447H23.3898L23.4555 75.6792H20.896L21.6042 70.6533H21.0907L20.3193 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.2271 73.4007C23.9919 75.0752 24.9859 76.2867 26.6602 76.2867C28.3345 76.2867 29.6674 75.0777 29.9026 73.4007C30.1379 71.7262 29.1439 70.5146 27.4696 70.5146C25.7952 70.5146 24.4623 71.7237 24.2271 73.4007ZM24.738 73.4007C24.9302 72.0348 25.9697 70.9801 27.4038 70.9801C28.8378 70.9801 29.5814 72.0348 29.3892 73.4007C29.197 74.7666 28.1575 75.8213 26.7234 75.8213C25.2894 75.8213 24.5458 74.7666 24.738 73.4007Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.6641 76.1451H31.175L31.5417 73.5398H32.7279C33.5878 73.5398 34.4427 73.0971 34.5818 72.1056C34.7437 70.9573 33.7927 70.6562 33.0187 70.6562H31.438L30.6666 76.1476L30.6641 76.1451ZM31.8831 71.1191H33.0162C33.6055 71.1191 34.1721 71.3974 34.0709 72.1031C33.9697 72.8239 33.3526 73.0718 32.7709 73.0718H31.6075L31.8831 71.1166V71.1191Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0596 76.1447H35.5705L36.2508 71.3059H36.266L37.7127 76.1447H37.9353L40.7098 71.3059H40.725L40.0446 76.1447H40.558L41.3294 70.6533H40.601L37.953 75.2594L36.5999 70.6533H35.831L35.0596 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0049 76.1447H45.4876L45.5508 75.6792H42.5815L42.8825 73.5394H45.5508L45.6166 73.074H42.9483L43.2214 71.1187H46.0693L46.1351 70.6533H42.7763L42.0049 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.6006 76.1447H47.1115L47.7893 71.3363H47.8045L50.4703 76.1447H51.1304L51.9018 70.6533H51.3883L50.7131 75.4617H50.6979L48.0321 70.6533H47.372L46.6006 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M53.8492 76.1447H54.3601L55.0658 71.1187H56.9424L57.0082 70.6533H52.744L52.6782 71.1187H54.5549L53.8492 76.1447Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6418 63.111L11.7405 62.4079C12.2412 58.849 10.5821 56.8457 7.72915 56.8457C4.64101 56.8457 1.86648 58.9982 1.41376 62.2156C0.961033 65.433 3.13108 67.5855 6.21923 67.5855C7.83791 67.5855 9.4313 67.0114 10.8325 65.5392L9.19356 64.1531C8.44239 64.9423 7.52935 65.5392 6.24958 65.5392C4.82311 65.5392 3.80132 64.6236 3.84178 63.111H11.6393H11.6418ZM4.11241 61.1937C4.50949 59.7242 5.58187 58.7655 7.09686 58.7655C8.69531 58.7655 9.54259 59.7039 9.35543 61.1937H4.11494H4.11241Z" fill="#00904C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5801 67.3305H15.1371L15.9211 61.7481C16.1134 60.3847 16.948 59.1479 18.6299 59.1479C20.4206 59.1479 20.241 61.0221 20.0817 62.1528L19.3533 67.3305H21.9103L22.8183 60.8754C23.1116 58.7862 22.4035 56.8486 19.6568 56.8486C17.9521 56.8486 16.8721 57.7213 16.2575 58.7457H16.2145L16.4447 57.1041H14.0167L12.5801 67.333V67.3305Z" fill="#00904C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3211 67.3305H29.0476L34.5132 57.1016H31.893L28.1371 64.2598H28.0941L26.3743 57.1016H23.582L26.3211 67.3305Z" fill="#00904C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.4072 67.3305H36.9642L38.4008 57.1016H35.8438L34.4072 67.3305Z" fill="#00904C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0459 67.3305H41.6029L42.3718 61.8543C42.4502 61.3004 43.0749 59.2769 45.4827 59.2769C45.8874 59.2769 46.2794 59.3629 46.6916 59.4691L47.0381 56.9979C46.7523 56.9119 46.4615 56.8486 46.1428 56.8486C44.7998 56.8486 43.5732 57.5518 42.8549 58.7229H42.8119L43.0395 57.1041H40.4825L39.0459 67.333V67.3305Z" fill="#00904C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.9391 62.2156C46.4864 65.433 48.6565 67.5855 51.7446 67.5855C54.8328 67.5855 57.6073 65.433 58.06 62.2156C58.5127 58.9982 56.3427 56.8457 53.2545 56.8457C50.1664 56.8457 47.3919 58.9982 46.9391 62.2156ZM49.4962 62.2156C49.7213 60.617 51.0567 59.1475 52.9308 59.1475C54.8049 59.1475 55.7281 60.617 55.503 62.2156C55.2779 63.8142 53.9425 65.2838 52.0684 65.2838C50.1942 65.2838 49.2711 63.8142 49.4962 62.2156Z" fill="#00904C" />
            <path d="M36.021 55.9759C36.021 55.9759 40.0146 55.9759 40.0146 51.8555C36.021 51.8555 36.021 55.9759 36.021 55.9759Z" fill="#7FB24A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M57.8146 74.4659H58.4343V74.2939H57.0078V74.4659H57.6275V76.1429H57.8146V74.4659Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M59.0085 74.2939H58.7227V76.1429H58.9098V74.5444H58.9149L59.5826 76.1429H59.6838L60.3515 74.5444H60.3565V76.1429H60.5462V74.2939H60.2579L59.6332 75.7989L59.0085 74.2939Z" fill="white" />
        </svg>

    )
}