import * as React from "react"
import Spacer from "../components/spacer"
import logo from "../images/footer.svg"
import { useStaticQuery, graphql } from "gatsby"
import Siera from "./siera"
import Peez from "./peez"
import Enviro from "./enviro"

const Footer = () => {
	const data = useStaticQuery(graphql`
		query ContactInfoFooterCopy {
			datoCmsTapestryLandingPage {
				email
				instagram
				facebook
				address
				people {
					email
					name
					phone
				}
			}
		}
	`)

	const page = data.datoCmsTapestryLandingPage
	const insta = page.instagram
	const facebook = page.facebook
	const people = page.people
	const address = page.address
	return (
		<div className='footer'>
			<Spacer />
			<div className='max-1200 ma flex pl40 pr40 m-flex-col m-p20'>
				<div className='logo '>
					<a href='/' className='link m-hide  '>
						<img width='70' className='mb70' src={logo} alt='Tapestry Logo' />
					</a>
					<a href='https://sieragroup.com.au' rel='noreferrer' className='' target='_blank'>
						<div className='m-mb40 flex align-end m-flex-col m-align-start'>
							<div>
								<h4 className='image-caption mb15 white'>Developed by</h4>
								<Siera className='m-mb40 mr40 m-mr0' />
							</div>
							<a target='_blank' href='https://youtu.be/3RXWgahgCb8'>
								<Enviro />
							</a>
						</div>
					</a>
				</div>
				<div className='mla w-100 max-450 info--wrapper'>
					<div className='flex info-item mb40'>
						<div className='mla flex m-flex-col m-m0 '>
							<div className='w-50 mr50'>
								<p className='mb20 m0 h4'>Address</p>
								<div className='address' dangerouslySetInnerHTML={{ __html: address.replace("\n", "<br/>") }}></div>
							</div>
							<div className='w-50'>
								<p className='mb20 m-mt40 m0 h4'>Contact</p>
								<div className='contact'>
									<a className='ul-link b-white' href={insta}>
										Instagram
									</a>
									<br />
									<a className='ul-link b-white' href={facebook}>
										{" "}
										Facebook
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className='flex info-item mt40'>
						<div className='mla  m-m0 '>
							<p className='mb20 m0 h4'>For More Information</p>
							<div className='flex mb30'>
								<div className={"w-50 m-pb20 "}>
									<span>
										Siera Property Group <br />
										tapestry@sieragroup.com.au <br /> 1300 474 372
									</span>
								</div>
							</div>

							<p className='mb20 m0 h4'>Disclaimer</p>
							<p className='op65   small-9  '>PHOTOGRAPHS AND OUTLINES ARE FOR PRESENTATION PURPOSES ONLY AND ARE INDICATIVE ONLY. THEY SHOULD NOT BE RELIED UPON AS AN ACCURATE REPRESENTATION OF THE FINAL PRODUCT. CORRECT AT TIME OF PRODUCTION. SPECIFICATIONS MAY CHANGE AT ANYTIME.</p>
						</div>
					</div>
				</div>
			</div>
			<Spacer />
		</div>
	)
}

export default Footer
