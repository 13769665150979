import React from 'react'

export default function Siera({ ...props }) {
  return (


    <svg {...props} width="98" height="22" viewBox="0 0 98 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4027 15.3388C17.4027 18.822 14.4495 22 8.70208 22C2.64136 22 0 18.1498 0 14.1773H3.10757C3.16903 16.3161 4.31881 19.3114 8.70058 19.3114C12.9894 19.3114 14.1692 17.1416 14.1692 15.5216C14.1692 13.6275 12.9579 12.5883 8.6691 12.0679C3.57228 11.4562 0.745036 9.71538 0.745036 6.04797C0.746535 2.68864 3.66672 0 8.73207 0C14.1077 0 16.4688 3.3608 16.5632 6.84395H13.5486C13.5486 4.94982 12.2429 2.68864 8.6706 2.68864C5.00389 2.68864 3.94704 4.46044 3.94704 5.86667C3.94704 7.18003 4.53768 8.49487 8.98091 9.07565C13.7974 9.71685 17.4027 10.8165 17.4027 15.3388Z" fill="white" />
      <path d="M24.1108 21.7557V0.245117H27.3428V21.7572H24.1108V21.7557Z" fill="white" />
      <path d="M38.2803 2.93376V9.65537H47.4786V12.344H38.2803V19.0656H49.4679V21.7543H35.0483V0.245117H49.4679V2.93376H38.2803Z" fill="white" />
      <path d="M55.8345 0.245117H63.9759C70.0666 0.245117 72.2418 2.99567 72.2418 6.84585C72.2418 9.6878 69.9122 11.8871 66.5873 12.3145C66.3999 12.3455 66.2755 12.4059 66.2755 12.5887C66.2755 12.8024 66.4314 12.8643 66.6173 12.8643C69.6634 13.0176 70.5029 14.1777 70.9361 15.3702L73.2372 21.7572H69.3216L67.9859 16.685C67.3953 14.3929 65.7178 13.6913 62.4858 13.6913H59.068V21.7572H55.836V0.245117H55.8345ZM63.6956 11.0012C66.7102 11.0012 69.0098 9.19841 69.0098 6.90629C69.0098 4.37095 67.4568 2.93376 64.4421 2.93376H59.0665V10.9997H63.6956V11.0012Z" fill="white" />
      <path d="M92.6245 16.6231H82.4939L80.722 21.7557H77.1167L85.0722 0.245117H90.0447L98.0002 21.7572H94.3949L92.6245 16.6231ZM91.7236 13.9345L88.1184 3.42314C87.9939 3.02662 87.8695 2.65959 87.808 2.13925C87.7766 1.95647 87.7766 1.77222 87.5592 1.77222C87.3418 1.77222 87.3418 1.955 87.3104 2.13925C87.2474 2.65812 87.1245 3.02515 87 3.42314L83.3948 13.9345H91.7236Z" fill="white" />
    </svg>

  )
}
