import { navigate } from "gatsby-link"
import React, { useEffect, useMemo } from "react"
import logo from "../images/logo.svg"
import Link from './transitionLink'
const Header = ({ siteTitle, location }) => {

  const home = useMemo(() => {
    if (location?.pathname === '/') {
      return true
    } else {
      return false
    }
  }, [location])


  useEffect(() => {
    const toggleNavigation = (e) => {
      var menu = document.querySelector('.inner--menu');
      var button = document.querySelector('.menu--button');
      if (!home) {
        navigate('/')
      } else {
        if (menu.classList.contains('active')) {
          menu.classList.remove('active');
          menu.style.width = '0px';
          button.innerHTML = 'Menu';
          button.classList.remove('white');
        } else {
          menu.classList.add('active');
          button.classList.add('white');
          button.innerHTML = 'Close';
          var width = document.querySelector('.inner--buttons').offsetWidth;
          menu.style.width = width + 'px';
        }
      }
    }

    document.querySelector('.menu--button').addEventListener("click", toggleNavigation);
  }, [home]);


  const scrollTo = (target) => {
    const toTop = document.querySelector(`#${target}`)?.offsetTop - 100
    window.scrollTo({ top: toTop, behavior: 'smooth' })
  }

  const menuOpen = (e) => {
    document.querySelector('.close--button').classList.add('active');
  }

  return (
    <header className="masthead">
      <div className="p40 pt20">
        <div className=" ma bb1 w-100 pb20 flex">
          <div className="logo flex">
            <Link to="/"  className='flex ' ><img width="100" src={logo} alt="Tapestry Logo" /></Link>
          </div>
          <div className="menu mla flex">
            <p className="button menu--button m0">{home ? 'Menu' : 'Home'}</p>
            {home ? <div className="inner--menu">
              <div className="inner--buttons h-100">
                <div className="flex h-100 ">
                  <button onClick={() => { scrollTo('design') }} data-target="#design" className="button ml5 m0">Design</button>
                  <button onClick={() => { scrollTo('art') }} data-target="#art" className="button ml5 m0 h-100">VIDEO</button>
                  <button onClick={() => { scrollTo('lifestyle') }} data-target="#lifestyle" className="button ml5 m0">Lifestyle</button>
                </div>
              </div>
            </div> : null}
            <p className="button enquire--button white ml5 m0" onClick={menuOpen}>ENQUIRE </p>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
